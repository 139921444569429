<template>
  <div class="product  w-100 box mb20" v-observe-visibility="visibilityChanged">
    <router-link
      class="block no-underline product-link"
      :to="productLink"
      data-testid="productLink"
    >
      <!-- <div
        class="product-cover bg-cl-secondary"
        :class="[{ sale: labelsActive && isOnSale }, { new: labelsActive && isNew }]"
      >
      </div> -->
      <div class="row mb10">
        <div class="col-md-12">
          <h2 class="mb0 mt0" v-if="!onlyImage">
            {{ product.name | htmlDecode }}
          </h2>
        </div>
      </div>
      <div class="row mb10">
        <div class="col-md-6 mb10">
          <p class="corporate fs20 weight-500 mt0 mb0">
            {{ product.sku }}
          </p>
        </div>
        <div class="col-xl-6 text-right mb10">
          <status
            :available="product.stock && product.stock.is_in_stock"
            :premium="DisableIfFreemium"
          />
        </div>
      </div>
      <div class="row mb10 description">
        <div class="col-md-12 ">
          <div class="cats inline">
            <span class="fs14" v-for="cat of product.category" :key="cat.id">
              {{ cat.name }}
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-xl-12">
          <product-attribute
            :product="product.attribute_code"
          />
        </div>
      </div> -->
      <div class="row align-items-center bottom-bar">
        <!-- <div class="col-xl-6">
          <div class="mt10 nomeinteresa">
            <not-interested
              :product="product"
            />
          </div>
        </div> -->
        <div class="col-xl-12">
          <div class="xs-nofloat xs-mt10 xs-mb30 addtocart">
            <add-to-cart
              :product="product"
              :disabled="DisableIfFreemium"
            />
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import rootStore from '@vue-storefront/core/store'
import { ProductTile } from '@vue-storefront/core/modules/catalog/components/ProductTile.ts'
import config from 'config'
// import ProductImage from './ProductImage'
// import AddToWishlist from 'theme/components/core/blocks/Wishlist/AddToWishlist'
// import AddToCompare from 'theme/components/core/blocks/Compare/AddToCompare'
import { IsOnWishlist } from '@vue-storefront/core/modules/wishlist/components/IsOnWishlist'
import { IsOnCompare } from '@vue-storefront/core/modules/compare/components/IsOnCompare'
// import { ProductOption } from '@vue-storefront/core/modules/catalog/components/ProductOption.ts'
import AddToCart from 'theme/components/core/AddToCart.vue'
import NotInterested from 'theme/components/core/blocks/Product/NotInterested'
import Status from 'theme/components/core/blocks/Product/Status'
import ProductAttribute from 'theme/components/core/ProductAttribute'
import { mapState } from 'vuex'

export default {
  mixins: [ProductTile, IsOnWishlist, IsOnCompare],
  components: {
    // ProductImage,
    AddToCart,
    NotInterested,
    // AddToWishlist,
    // AddToCompare,
    // ProductOption,
    Status,
    ProductAttribute
  },
  props: {
    labelsActive: {
      type: Boolean,
      default: true
    },
    onlyImage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    thumbnailObj () {
      return {
        src: this.thumbnail,
        loading: this.thumbnail
      }
    },
    favoriteIcon () {
      return this.isOnWishlist ? 'favorite' : 'favorite_border'
    },
    ...mapState({
      currentUser: (state) => state.user.current
    }),
    sfServiceModel () {
      if (this.currentUser.custom_attributes) {
        for (let attribute of this.currentUser.custom_attributes) {
          if (attribute.attribute_code === 'modelo_servicio') {
            return attribute.value
          }
        }
      }
      return ''
    },
    DisableIfFreemium () {
      return !this.product.freemium && this.sfServiceModel === 'Freemium'
    }
  },
  methods: {
    onProductPriceUpdate (product) {
      if (product.sku === this.product.sku) {
        Object.assign(this.product, product)
      }
    },
    visibilityChanged (isVisible, entry) {
      if (
        isVisible &&
        config.products.configurableChildrenStockPrefetchDynamic &&
        config.products.filterUnavailableVariants &&
        this.product.type_id === 'configurable' &&
        this.product.configurable_children &&
        this.product.configurable_children.length > 0
      ) {
        const skus = [this.product.sku]
        for (const confChild of this.product.configurable_children) {
          const cachedItem = rootStore.state.stock.cache[confChild.id]
          if (typeof cachedItem === 'undefined' || cachedItem === null) {
            skus.push(confChild.sku)
          }
        }
        if (skus.length > 0) {
          rootStore.dispatch('stock/list', { skus: skus }) // store it in the cache
        }
      }
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-priceupdate', this.onProductPriceUpdate)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-priceupdate', this.onProductPriceUpdate)
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/animations/transitions';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$bg-secondary: color(secondary, $colors-background);
$border-secondary: color(secondary, $colors-border);
$color-white: color(white);

// .box{
//   height:100%;
// }
.box a.product-link{
  padding: 10%;
  height: 77%;
  display: flex;
  flex-direction: column;
}
.description {
  flex: 1 0 auto;
}
h2{
  font-size: 25px;
  color: #4A4A4A;
  line-height: 33px;
  font-weight:500;
}

div.cats span:not(:last-of-type){
  border-right:1px solid #4A4A4A;
  padding-right:10px;
}
div.cats span:not(:first-of-type){
  padding-left:10px;
}
.cats span{
  font-size:14px;
}
.product {
  position: relative;
  @media (max-width: 767px) {
    padding-bottom: 10px;
  }
  &__icons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    padding-top: 10px;
  }
  &__icon {
    padding-top: 10px;
    opacity: 0;
    z-index: 2;
    transition: 0.3s opacity $motion-main;
    @media (max-width: 767px) {
      opacity: 1;
    }
    &--active {
      opacity: 1;
    }
  }
  &:hover {
    .product__icon {
      opacity: 1;
    }
  }
}

.price-original {
  text-decoration: line-through;
}

%label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: $border-secondary;
  text-transform: uppercase;
  color: $color-white;
  font-size: 12px;
}
.addtocart button{
    min-width: 200px;
}
.nomeinteresa {
  font-size:12px;
}
.product-cover {
  overflow: hidden;

  &__thumb {
    padding-bottom: calc(143.88% / (164.5 / 100));
    @media screen and (min-width: 768px) {
      padding-bottom: calc(300% / (276.5 / 100));
    }
    opacity: 0.8;
    will-change: opacity, transform;
    transition: 0.3s opacity $motion-main, 0.3s transform $motion-main;
  }

  @media screen and (min-width: 768px) {
    &:hover {
      .product-cover__thumb {
        opacity: 1;
        transform: scale(1.1);
      }
      &.sale::after,
      &.new::after {
        opacity: 0.8;
      }
    }
  }

  &.sale {
    &::after {
      @extend %label;
      content: 'Sale';
    }
  }
  &.new {
    &::after {
      @extend %label;
      content: 'New';
    }
  }
}
</style>
