var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.compontentType,
    {
      tag: "component",
      staticClass:
        "no-outline button-full block brdr-none w-100 px10 py20 bg-cl-mine-shaft :bg-cl-th-secondary ripple weight-400 h4 cl-white sans-serif fs-medium",
      class: {
        "no-underline pointer align-center border-box": _vm.link,
        disabled: _vm.disabled,
        "button-disabled": _vm.disabled
      },
      attrs: {
        type: !_vm.link ? _vm.type : false,
        to: _vm.redirectionLink,
        "data-testid": "subscribeSubmit",
        disabled: _vm.disabled
      }
    },
    [_vm._t("default", [_vm._v("\n    Button\n  ")])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }