var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: _vm.visibilityChanged,
          expression: "visibilityChanged"
        }
      ],
      staticClass: "product  w-100 box mb20"
    },
    [
      _c(
        "router-link",
        {
          staticClass: "block no-underline product-link",
          attrs: { to: _vm.productLink, "data-testid": "productLink" }
        },
        [
          _c("div", { staticClass: "row mb10" }, [
            _c("div", { staticClass: "col-md-12" }, [
              !_vm.onlyImage
                ? _c("h2", { staticClass: "mb0 mt0" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("htmlDecode")(_vm.product.name)) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb10" }, [
            _c("div", { staticClass: "col-md-6 mb10" }, [
              _c("p", { staticClass: "corporate fs20 weight-500 mt0 mb0" }, [
                _vm._v("\n          " + _vm._s(_vm.product.sku) + "\n        ")
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-xl-6 text-right mb10" },
              [
                _c("status", {
                  attrs: {
                    available:
                      _vm.product.stock && _vm.product.stock.is_in_stock,
                    premium: _vm.DisableIfFreemium
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb10 description" }, [
            _c("div", { staticClass: "col-md-12 " }, [
              _c(
                "div",
                { staticClass: "cats inline" },
                _vm._l(_vm.product.category, function(cat) {
                  return _c("span", { key: cat.id, staticClass: "fs14" }, [
                    _vm._v("\n            " + _vm._s(cat.name) + "\n          ")
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row align-items-center bottom-bar" }, [
            _c("div", { staticClass: "col-xl-12" }, [
              _c(
                "div",
                { staticClass: "xs-nofloat xs-mt10 xs-mb30 addtocart" },
                [
                  _c("add-to-cart", {
                    attrs: {
                      product: _vm.product,
                      disabled: _vm.DisableIfFreemium
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }