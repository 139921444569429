export const categoryFilters = [
  {
    'id': 117,
    'label': 'Automatismos & Estudio del Dato',
    'type': 'category'
  },
  {
    'id': 118,
    'label': 'Desarrollo de Software',
    'type': 'category'
  },
  {
    'id': 126,
    'label': 'Comunicaciones Unificadas',
    'type': 'category'
  },
  {
    'id': 120,
    'label': 'Continuidad de Negocio',
    'type': 'category'
  },
  {
    'id': 124,
    'label': 'Hardware & Cableado',
    'type': 'category'
  },
  {
    'id': 119,
    'label': 'Marketing & Web',
    'type': 'category'
  },
  {
    'id': 122,
    'label': 'Seguridad & Ciberseguridad',
    'type': 'category'
  },
  {
    'id': 123,
    'label': 'Servidores',
    'type': 'category'
  },
  {
    'id': 125,
    'label': 'Software de Gestión Empresarial',
    'type': 'category'
  },
  {
    'id': 116,
    'label': 'Soporte & Mantenimiento',
    'type': 'category'
  },
  {
    'id': 121,
    'label': 'Telecomunicaciones - Datos',
    'type': 'category'
  }]

export const empleadosFilters = [
  {
    'id': 1,
    'label': '1 - 10',
    'type': 'empleados'
  },
  {
    'id': 10,
    'label': '10 - 20',
    'type': 'empleados'
  },
  {
    'id': 20,
    'label': '20 - 50',
    'type': 'empleados'
  },
  {
    'id': 50,
    'label': '50 - 150',
    'type': 'empleados'
  },
  {
    'id': 150,
    'label': '150 - 300',
    'type': 'empleados'
  },
  {
    'id': 300,
    'label': '300 - 500',
    'type': 'empleados'
  },
  {
    'id': 500,
    'label': '500 - 750',
    'type': 'empleados'
  },
  {
    'id': 750,
    'label': '750 - 1000',
    'type': 'empleados'
  },
  {
    'id': 1000,
    'label': '+1000',
    'type': 'empleados'
  }]

export const facturacionFilters = [
  {
    'id': 0,
    'label': '500K€ a 1m€',
    'type': 'intervalo_facturacion'
  },
  {
    'id': 1,
    'label': '+1m€',
    'type': 'intervalo_facturacion'
  },
  {
    'id': 2,
    'label': '2m€ a 5m€',
    'type': 'intervalo_facturacion'
  },
  {
    'id': 5,
    'label': '5m€ a 10m€',
    'type': 'intervalo_facturacion'
  },
  {
    'id': 10,
    'label': '10m€ a 20m€',
    'type': 'intervalo_facturacion'
  },
  {
    'id': 20,
    'label': '20m€ - 30m€',
    'type': 'intervalo_facturacion'
  },
  {
    'id': 30,
    'label': '30m€ a 50m€',
    'type': 'intervalo_facturacion'
  },
  {
    'id': 50,
    'label': '50m€ a 75m€',
    'type': 'intervalo_facturacion'
  },
  {
    'id': 75,
    'label': '75m€ a 100m€',
    'type': 'intervalo_facturacion'
  },
  {
    'id': 100,
    'label': '100m€ a 200m€',
    'type': 'intervalo_facturacion'
  },
  {
    'id': 200,
    'label': '200m€ a 300m€',
    'type': 'intervalo_facturacion'
  },
  {
    'id': 300,
    'label': '300m€ a 500m€',
    'type': 'intervalo_facturacion'
  },
  {
    'id': 500,
    'label': '+500m€',
    'type': 'intervalo_facturacion'
  }]

export const decisionFilters = [
  {
    'id': 1,
    'label': '1 a 3 meses',
    'type': 'ciclo_decision'
  },
  {
    'id': 2,
    'label': '3 a 6 meses',
    'type': 'ciclo_decision'
  }]

export const ciudadFilters = [
  {
    'id': 0,
    'label': 'A Coruña',
    'type': 'ciudad'
  },
  {
    'id': 1,
    'label': 'Araba/Álava',
    'type': 'ciudad'
  },
  {
    'id': 2,
    'label': 'Albacete',
    'type': 'ciudad'
  },
  {
    'id': 3,
    'label': 'Alicante',
    'type': 'ciudad'
  },
  {
    'id': 4,
    'label': 'Almería',
    'type': 'ciudad'
  },
  {
    'id': 5,
    'label': 'Asturias',
    'type': 'ciudad'
  },
  {
    'id': 6,
    'label': 'Ávila',
    'type': 'ciudad'
  },
  {
    'id': 7,
    'label': 'Badajoz',
    'type': 'ciudad'
  },
  {
    'id': 8,
    'label': 'Baleares',
    'type': 'ciudad'
  },
  {
    'id': 9,
    'label': 'Barcelona',
    'type': 'ciudad'
  },
  {
    'id': 47,
    'label': 'Bizkaia',
    'type': 'ciudad'
  },
  {
    'id': 10,
    'label': 'Burgos',
    'type': 'ciudad'
  },
  {
    'id': 11,
    'label': 'Cáceres',
    'type': 'ciudad'
  },
  {
    'id': 12,
    'label': 'Cádiz',
    'type': 'ciudad'
  },
  {
    'id': 13,
    'label': 'Cantabria',
    'type': 'ciudad'
  },
  {
    'id': 14,
    'label': 'Castellón',
    'type': 'ciudad'
  },
  {
    'id': 15,
    'label': 'Ciudad Real',
    'type': 'ciudad'
  },
  {
    'id': 16,
    'label': 'Córdoba',
    'type': 'ciudad'
  },
  {
    'id': 17,
    'label': 'Cuenca',
    'type': 'ciudad'
  },
  {
    'id': 18,
    'label': 'Gipuzkoa',
    'type': 'ciudad'
  },
  {
    'id': 19,
    'label': 'Girona',
    'type': 'ciudad'
  },
  {
    'id': 20,
    'label': 'Granada',
    'type': 'ciudad'
  },
  {
    'id': 21,
    'label': 'Guadalajara',
    'type': 'ciudad'
  },
  {
    'id': 22,
    'label': 'Huelva',
    'type': 'ciudad'
  },
  {
    'id': 23,
    'label': 'Huesca',
    'type': 'ciudad'
  },
  {
    'id': 24,
    'label': 'Jaén',
    'type': 'ciudad'
  },
  {
    'id': 25,
    'label': 'La Rioja',
    'type': 'ciudad'
  },
  {
    'id': 26,
    'label': 'Las Palmas',
    'type': 'ciudad'
  },
  {
    'id': 27,
    'label': 'León',
    'type': 'ciudad'
  },
  {
    'id': 28,
    'label': 'Lleida',
    'type': 'ciudad'
  },
  {
    'id': 29,
    'label': 'Lugo',
    'type': 'ciudad'
  },
  {
    'id': 30,
    'label': 'Madrid',
    'type': 'ciudad'
  },
  {
    'id': 31,
    'label': 'Málaga',
    'type': 'ciudad'
  },
  {
    'id': 32,
    'label': 'Murcia',
    'type': 'ciudad'
  },
  {
    'id': 33,
    'label': 'Navarra',
    'type': 'ciudad'
  },
  {
    'id': 34,
    'label': 'Ourense',
    'type': 'ciudad'
  },
  {
    'id': 35,
    'label': 'Palencia',
    'type': 'ciudad'
  },
  {
    'id': 36,
    'label': 'Pontevedra',
    'type': 'ciudad'
  },
  {
    'id': 37,
    'label': 'Salamanca',
    'type': 'ciudad'
  },
  {
    'id': 38,
    'label': 'Santa Cruz de Tenerife',
    'type': 'ciudad'
  },
  {
    'id': 39,
    'label': 'Segovia',
    'type': 'ciudad'
  },
  {
    'id': 40,
    'label': 'Sevilla',
    'type': 'ciudad'
  },
  {
    'id': 41,
    'label': 'Soria',
    'type': 'ciudad'
  },
  {
    'id': 42,
    'label': 'Tarragona',
    'type': 'ciudad'
  },
  {
    'id': 43,
    'label': 'Teruel',
    'type': 'ciudad'
  },
  {
    'id': 44,
    'label': 'Toledo',
    'type': 'ciudad'
  },
  {
    'id': 45,
    'label': 'Valencia',
    'type': 'ciudad'
  },
  {
    'id': 46,
    'label': 'Valladolid',
    'type': 'ciudad'
  },
  {
    'id': 48,
    'label': 'Zamora',
    'type': 'ciudad'
  },
  {
    'id': 49,
    'label': 'Zaragoza',
    'type': 'ciudad'
  }]
