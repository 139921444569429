<template>
  <div>
    <div v-if="!available" class="status ko">
      {{ $t('Not available') }}
    </div>
    <div v-else-if="premium" class="status premium" :title="spanTitle" @click.stop.prevent="onStatusClick">
      {{ $t('Premium') }}<span class="icon">i</span>
    </div>
    <div v-else-if="recommended" class="status recommended">
      {{ $t('Recommended') }}
    </div>
    <div v-else class="status ok">
      {{ $t('Available') }}
    </div>
  </div>
</template>

<script>
import i18n from '@vue-storefront/i18n'

export default {
  name: 'Status',
  data () {
    return {
      spanTitle: i18n.t('Only premium users can buy this lead. Contact us if you want to be a premium user.')
    }
  },
  props: {
    available: {
      type: Boolean,
      required: true
    },
    recommended: {
      type: Boolean,
      required: false,
      default: false
    },
    premium: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    onStatusClick () {
      if (this.premium) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: this.spanTitle,
          action1: { label: this.$t('OK') }
        })
      }
    }
  }
}
</script>

<style  lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$corporate: color(corporate);
$green: color(green);

.status {
  font-size:14px;
  line-height:14px;
  font-weight:300;
  color:#FFFFFF;
  border-radius:50px;
  padding:4px 5px;
  display:inline-block;
  text-align:center;
  min-width:135px;
}

.status.recommended,
.status.premium {
  background: #FBD22C;
}
.status.premium {
  cursor: pointer;
}
.status.ok {
  background: $green;
}
.status.ko {
  background: #FF556A;
}
span.icon {
  background-color: white;
  color: #FBD22C;
  font-weight: bold;
  border-radius: 100%;
  padding: 0px 6px;
  font-size: 11px;
  margin-left: 8px;
}
</style>
