var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.available
      ? _c("div", { staticClass: "status ko" }, [
          _vm._v("\n    " + _vm._s(_vm.$t("Not available")) + "\n  ")
        ])
      : _vm.premium
      ? _c(
          "div",
          {
            staticClass: "status premium",
            attrs: { title: _vm.spanTitle },
            on: {
              click: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.onStatusClick($event)
              }
            }
          },
          [
            _vm._v("\n    " + _vm._s(_vm.$t("Premium"))),
            _c("span", { staticClass: "icon" }, [_vm._v("i")])
          ]
        )
      : _vm.recommended
      ? _c("div", { staticClass: "status recommended" }, [
          _vm._v("\n    " + _vm._s(_vm.$t("Recommended")) + "\n  ")
        ])
      : _c("div", { staticClass: "status ok" }, [
          _vm._v("\n    " + _vm._s(_vm.$t("Available")) + "\n  ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }