import { render, staticRenderFns } from "./ProductTile.vue?vue&type=template&id=01fac803&scoped=true&"
import script from "./ProductTile.vue?vue&type=script&lang=js&"
export * from "./ProductTile.vue?vue&type=script&lang=js&"
import style0 from "./ProductTile.vue?vue&type=style&index=0&id=01fac803&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01fac803",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/vuestorefront/vue-storefront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01fac803')) {
      api.createRecord('01fac803', component.options)
    } else {
      api.reload('01fac803', component.options)
    }
    module.hot.accept("./ProductTile.vue?vue&type=template&id=01fac803&scoped=true&", function () {
      api.rerender('01fac803', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/themes/default-amp/components/core/ProductTile.vue"
export default component.exports