var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "breadcrumbs h5 cl-gray" },
    [
      _vm._l(_vm.paths, function(link) {
        return _c(
          "span",
          { key: link.route_link },
          [
            _c("router-link", { attrs: { to: link.route_link } }, [
              _vm._v(
                "\n      " + _vm._s(_vm._f("htmlDecode")(link.name)) + "\n    "
              )
            ]),
            _vm._v(" /\n  ")
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("span", { staticClass: "cl-mine-shaft" }, [
        _vm._v("\n    " + _vm._s(_vm._f("htmlDecode")(_vm.current)) + "\n  ")
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }