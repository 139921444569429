<template>
  <button-full
    @click.native.stop.prevent="addToCart(product)"
    :disabled="isProductDisabled"
    data-testid="addToCart"
    class="pb5"
  >
    <span>{{ $t('Add to cart') }}</span><i class="material-icons notranslate">shopping_cart</i>
  </button-full>
</template>

<script>
import { formatProductMessages } from '@vue-storefront/core/filters/product-messages'
import { notifications } from '@vue-storefront/core/modules/cart/helpers'
import focusClean from 'theme/components/theme/directives/focusClean'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import { mapGetters } from 'vuex'

export default {
  directives: { focusClean },
  components: { ButtonFull },
  props: {
    product: {
      required: true,
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onAfterRemovedVariant () {
      this.$forceUpdate()
    },
    forcePrice (product) {
      // console.log('addToCart/forcePrice: product = ' + JSON.stringify(product))
      let forcedProduct = {...product}
      let forcedPrice = 150
      let taxOperator = 1.21
      forcedProduct.regular_price = forcedPrice
      forcedProduct.originalPrice = forcedPrice
      forcedProduct.originalPriceInclTax = forcedPrice * taxOperator
      forcedProduct.original_price = forcedPrice
      forcedProduct.original_price_incl_tax = forcedPrice * taxOperator
      forcedProduct.original_final_price = forcedPrice
      forcedProduct.finalPrice = forcedPrice
      forcedProduct.finalPriceInclTax = forcedPrice * taxOperator
      forcedProduct.final_price = forcedPrice
      forcedProduct.final_price_incl_tax = forcedPrice * taxOperator
      forcedProduct.price = forcedPrice
      forcedProduct.price_incl_tax = forcedPrice * taxOperator
      forcedProduct.priceInclTax = forcedPrice * taxOperator
      // console.log('addToCart/forcePrice: forcedProduct = ' + JSON.stringify(forcedProduct))
      return forcedProduct
    },
    async addToCart (product) {
      try {
        product = this.forcePrice(product)
        const diffLog = await this.$store.dispatch('cart/addItem', { productToAdd: product })
        diffLog.clientNotifications.forEach(notificationData => {
          this.notifyUser(notificationData)
        })
        this.$gtm.trackEvent({
          event: 'add to cart',
          ecommerce: {
            currencyCode: 'EUR',
            add: {
              products: [{
                name: this.product.name,
                id: this.product.sku,
                quantity: 1
              }]
            }
          }
        })
      } catch (message) {
        this.notifyUser(notifications.createNotification({ type: 'error', message }))
      }
    },
    notifyUser (notificationData) {
      this.$store.dispatch('notification/spawnNotification', notificationData, { root: true })
    }
  },
  computed: {
    ...mapGetters({
      isAddingToCart: 'cart/getIsAdding'
    }),
    isProductDisabled () {
      return this.disabled ||
             formatProductMessages(this.product.errors) !== '' ||
             this.isAddingToCart ||
             !this.product.stock.is_in_stock ||
             this.product.stock.qty === 0
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-removevariant', this.onAfterRemovedVariant)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-removevariant')
  }
}
</script>

<style lang="scss" scoped>
  button{
    text-transform:uppercase;
  }
  button span {
    position: relative;
    bottom: 1px;
  }
  button i {
    margin-left: 10px;
    font-size: 16px;
  }
</style>
