var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-listing row m0 center-xs start-md" },
    _vm._l(_vm.products, function(product) {
      return _c(
        "div",
        { key: product.id, staticClass: "col-lg-4 col-sm-6  col-xs-12 item" },
        [_c("product-tile", { attrs: { product: product } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }