var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "lh30 h5" }, [
    _c("span", [_vm._v(_vm._s(_vm._f("htmlDecode")(_vm.label)) + " ")]),
    _vm._v(" "),
    _c("span", { staticClass: "weight-700" }, [
      _vm._v(_vm._s(_vm._f("htmlDecode")(_vm.value)))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }