import { render, staticRenderFns } from "./Info.vue?vue&type=template&id=f0c48530&"
import script from "./Info.vue?vue&type=script&lang=js&"
export * from "./Info.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/vuestorefront/vue-storefront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f0c48530')) {
      api.createRecord('f0c48530', component.options)
    } else {
      api.reload('f0c48530', component.options)
    }
    module.hot.accept("./Info.vue?vue&type=template&id=f0c48530&", function () {
      api.rerender('f0c48530', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/payment-cash-on-delivery/components/Info.vue"
export default component.exports