import { render, staticRenderFns } from "./Status.vue?vue&type=template&id=d02bedac&scoped=true&"
import script from "./Status.vue?vue&type=script&lang=js&"
export * from "./Status.vue?vue&type=script&lang=js&"
import style0 from "./Status.vue?vue&type=style&index=0&id=d02bedac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d02bedac",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/vuestorefront/vue-storefront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d02bedac')) {
      api.createRecord('d02bedac', component.options)
    } else {
      api.reload('d02bedac', component.options)
    }
    module.hot.accept("./Status.vue?vue&type=template&id=d02bedac&scoped=true&", function () {
      api.rerender('d02bedac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/themes/default/components/core/blocks/Product/Status.vue"
export default component.exports