import {
  prepareNewQuery,
  getProductsFilters,
  filters_names,
  applyNumFiltersToQuery,
  applyCategoriesToQuery,
  applyTextFiltersToProducts,
  getRecommendedProducts,
  applyNotInterested,
  applyShowbaasOutofstock,
  strNumToSKU,
  removeBoughtProducts,
  applySearchText,
  moveUnavailableProducts
} from 'theme/helpers/productList'
import FilterVariant from '@vue-storefront/core/modules/catalog-next/types/FilterVariant'
import Vue from 'vue'
import fetch from 'isomorphic-fetch'
import config from 'config'

export const homepageStore = {
  namespaced: true,
  state: {
    products: [],
    filters: {},
    current_search_query: {
      search: '',
      filters: {}
    },
    keyToForceRerender: 0,
    userSalesforceId: '',
    freemium: false,
    notInterested: '',
    loadMoreProductsNum: 10,
    initialProductsToShow: 40,
    productsToShow: 40,
    totalProducts: 0,
    formerTotalProducts: 0
  },
  actions: {
    async loadProducts ({ commit, dispatch, getters, rootGetters }, freemium = false, loadAll = false) {
      commit('SET_FREEMIUM', freemium)
      let productsQuery = prepareNewQuery()
      let searchText
      let onlyNumbers = false
      let isRP = false
      if (loadAll === false) {
        searchText = getters.getSearchText
        searchText = searchText.trim()
        if (searchText.length > 1) {
          onlyNumbers = !isNaN(searchText)
          if (!onlyNumbers) {
            // eslint-disable-next-line no-useless-escape
            isRP = /[Rr][Pp][0-9\s\#]/g.test(searchText)
            if (isRP) {
              searchText = searchText.replace('RP', '')
              searchText = searchText.replace('rp', '')
              searchText = searchText.replace('#', '')
              searchText = searchText.trim().split(' ').filter(word => !isNaN(word)).join(' ')
            }
          }
          // if (onlyNumbers) {
          //   searchText = strNumToSKU(searchText)
          //   productsQuery = productsQuery.setSearchText(searchText)
          //   isRP = true
          // }
          // productsQuery = productsQuery.setSearchText(searchText)
        }
        productsQuery = applyCategoriesToQuery(productsQuery, getters.getCurrentFilters)
        productsQuery = applyNumFiltersToQuery(productsQuery, getters.getCurrentFilters)
      }
      // if (getters.getFreemium) {
      //   productsQuery = productsQuery.applyFilter({ key: 'freemium', value: { 'in': [1] } })
      // }

      const response = await dispatch('product/list', {
        query: productsQuery,
        size: 9999,
        sort: '_score' // created_at:desc
      }, { root: true })
      let productList = response.items
      productList = getRecommendedProducts(productList, getters.getUserSalesforceId)
      if (loadAll === false) {
        if (searchText.length > 1) productList = applySearchText(productList, searchText, onlyNumbers || isRP)
        productList = applyTextFiltersToProducts(productList, getters.getCurrentFilters)
        productList = applyNotInterested(productList, getters.getNotInterested)
        productList = applyShowbaasOutofstock(productList)
        productList = removeBoughtProducts(productList, rootGetters['user/getOrdersHistory'])
        productList = moveUnavailableProducts(productList)
        productList = productList.slice(0, getters.getProductsToShow)
      }
      commit('SET_PRODUCTS', productList)
      commit('SET_TOTAL_PRODUCTS', productList.length)
      // await dispatch('configureFilters')
    },
    async loadMoreProducts ({ commit, dispatch, getters }, freemium = false) {
      if (getters.getTotalProducts > getters.getFormerTotalProducts) {
        console.log('Loading more products...');
        commit('SET_FORMER_TOTAL_PRODUCTS', getters.getTotalProducts)
        commit('SET_PRODUCTS_TO_SHOW', getters.getTotalProducts + getters.getLoadMoreProductsNum)
        dispatch('loadProducts', freemium)
      }
    },
    resetInfiniteScroll ({ commit, getters }) {
      commit('SET_PRODUCTS_TO_SHOW', getters.getInitialProductsToShow)
      commit('SET_TOTAL_PRODUCTS', 0)
      commit('SET_FORMER_TOTAL_PRODUCTS', 0)
    },
    async configureFilters ({ commit, state }) {
      commit('SET_FILTERS', getProductsFilters(state.products))
      commit('FORCE_RERENDER')
    },
    async switchSearchFilters ({ dispatch, getters, commit }, filterVariants: FilterVariant[] = []) {
      if (!filterVariants) return

      let newSearchFilters = getters.getCurrentFilters
      filterVariants.forEach(filterVariant => {
        if (getters.getCurrentFilters[filterVariant.type]) {
          let existingVariantType = getters.getCurrentFilters[filterVariant.type]
          if (existingVariantType.length > 0) {
            let index = -1
            for (let i = 0; i < existingVariantType.length; i++) {
              if (existingVariantType[i].id === filterVariant.id) {
                index = i
              }
            }
            if (index > -1) {
              newSearchFilters[filterVariant.type].splice(index, 1)
              if (newSearchFilters[filterVariant.type].length === 0) {
                delete newSearchFilters[filterVariant.type];
              }
            } else {
              newSearchFilters[filterVariant.type].push(filterVariant)
            }
          }
        } else {
          newSearchFilters[filterVariant.type] = [filterVariant]
        }
      })
      // console.log('newSearchFilters in homepage.ts -> ' + JSON.stringify(newSearchFilters))
      commit('SET_CURRENT_FILTERS', newSearchFilters)
      dispatch('resetInfiniteScroll')
      await dispatch('loadProducts', getters.getFreemium)
    },
    async resetSearchFilters ({ commit, dispatch, getters }) {
      commit('SET_CURRENT_FILTERS', {})
      commit('SET_SEARCH_TEXT', '')
      dispatch('resetInfiniteScroll')
      await dispatch('loadProducts', getters.getFreemium)
    },
    async addNotInterestedItem ({ commit, getters, dispatch }, { email, itemSKU }) {
      var formData = new FormData()

      formData.append('email', email)
      formData.append('nmi', itemSKU)

      await fetch(`https://${config.server.domain}/custom-api/setnmi`, {
        method: 'POST',
        mode: 'cors',
        body: formData
      })
        .then(response => response.json())
        .catch(error => console.error('homepage/setNMI error:', error))
        .then(async response => {
          console.log('homepage/setNMI success:', response)
          commit('SET_NOT_INTERESTED', `${getters.getNotInterested},${itemSKU}`)
          await dispatch('loadProducts', getters.getFreemium)
          commit('FORCE_RERENDER')
        })
    },
    async saveCurrentFilters ({ commit, getters }, email) {
      var formData = new FormData()

      formData.append('email', email)
      formData.append('interests', JSON.stringify(getters.getCurrentFilters))

      await fetch(`https://${config.server.domain}/custom-api/setint`, {
        method: 'POST',
        mode: 'cors',
        body: formData
      })
        .then(response => response.json())
        .catch(error => console.error('homepage/setNMI error:', error))
        .then(async response => {
          console.log('homepage/saveCurrentFilters success:', response)
          commit('SET_CURRENT_FILTERS', getters.getCurrentFilters)
          // await dispatch('loadProducts', getters.getFreemium)
          commit('FORCE_RERENDER')
        })
    }
  },
  mutations: {
    SET_PRODUCTS (state, products) {
      state.products = []
      for (let i = 0; i < products.length; i++) {
        Vue.set(state.products, i, products[i])
      }
    },
    SET_FILTERS (state, filters) {
      state.filters = Object.assign({}, state.filters, filters)
    },
    SET_CURRENT_FILTERS (state, filters) {
      state.current_search_query.filters = Object.assign({}, filters)
    },
    FORCE_RERENDER (state) {
      state.keyToForceRerender += 1
    },
    SET_SEARCH_TEXT (state, search) {
      state.current_search_query.search = search
    },
    SET_USER_SALESFORCE_ID (state, userSalesforceId) {
      state.userSalesforceId = userSalesforceId
    },
    SET_FREEMIUM (state, freemium) {
      state.freemium = freemium
    },
    SET_NOT_INTERESTED (state, notInterested) {
      state.notInterested = notInterested
    },
    SET_PRODUCTS_TO_SHOW (state, productsToShow) {
      state.productsToShow = productsToShow
    },
    SET_TOTAL_PRODUCTS (state, totalProducts) {
      state.totalProducts = totalProducts
    },
    SET_FORMER_TOTAL_PRODUCTS (state, formerTotalProducts) {
      state.formerTotalProducts = formerTotalProducts
    }
  },
  getters: {
    getProducts (state) {
      return state.products
    },
    getAvailableFilters (state) {
      return state.filters
    },
    getCurrentFilters (state) {
      return state.current_search_query.filters
    },
    hasActiveFilters (state, getters) {
      return !!Object.keys(getters.getCurrentFilters).length
    },
    getSystemFilterNames () {
      return filters_names
    },
    getForceRerender (state) {
      return state.keyToForceRerender
    },
    getSearchText (state) {
      return state.current_search_query.search
    },
    getUserSalesforceId (state) {
      return state.userSalesforceId
    },
    getFreemium (state) {
      return state.freemium
    },
    getNotInterested (state) {
      return state.notInterested
    },
    getLoadMoreProductsNum (state) {
      return state.loadMoreProductsNum
    },
    getInitialProductsToShow (state) {
      return state.initialProductsToShow
    },
    getProductsToShow (state) {
      return state.productsToShow
    },
    getTotalProducts (state) {
      return state.totalProducts
    },
    getFormerTotalProducts (state) {
      return state.formerTotalProducts
    }
  }
}
