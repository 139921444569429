var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button-full",
    {
      staticClass: "pb5",
      attrs: { disabled: _vm.isProductDisabled, "data-testid": "addToCart" },
      nativeOn: {
        click: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.addToCart(_vm.product)
        }
      }
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.$t("Add to cart")))]),
      _c("i", { staticClass: "material-icons notranslate" }, [
        _vm._v("shopping_cart")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }