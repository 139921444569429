var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: _vm.visibilityChanged,
          expression: "visibilityChanged"
        }
      ],
      staticClass: "product align-center w-100 pb20"
    },
    [
      _c(
        "router-link",
        {
          staticClass: "block no-underline product-link",
          attrs: { to: _vm.productLink, "data-testid": "productLink" }
        },
        [
          _c(
            "div",
            {
              staticClass: "product-image relative bg-cl-secondary",
              class: [
                { sale: _vm.labelsActive && _vm.isOnSale },
                { new: _vm.labelsActive && _vm.isNew }
              ]
            },
            [
              _c("amp-img", {
                attrs: {
                  alt: _vm.product.name,
                  src: _vm.thumbnailObj.src,
                  height: "300",
                  width: "310"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.onlyImage
            ? _c("p", { staticClass: "mb0 cl-accent mt10" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("htmlDecode")(_vm.product.name)) +
                    "\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.product.special_price &&
          parseFloat(_vm.product.original_price_incl_tax) > 0 &&
          !_vm.onlyImage
            ? _c(
                "span",
                { staticClass: "price-original mr5 lh30 cl-secondary" },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm._f("price")(
                          _vm.product.original_price_incl_tax,
                          _vm.storeView
                        )
                      ) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.product.special_price &&
          parseFloat(_vm.product.special_price) > 0 &&
          !_vm.onlyImage
            ? _c(
                "span",
                { staticClass: "price-special lh30 cl-accent weight-700" },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm._f("price")(
                          _vm.product.price_incl_tax,
                          _vm.storeView
                        )
                      ) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.product.special_price &&
          parseFloat(_vm.product.price_incl_tax) > 0 &&
          !_vm.onlyImage
            ? _c("span", { staticClass: "lh30 cl-secondary" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm._f("price")(_vm.product.price_incl_tax, _vm.storeView)
                    ) +
                    "\n    "
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }