var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { "data-sku": _vm.sku } }, [
    _c(
      "span",
      {
        staticClass: "corporate pointer uppercase",
        attrs: { "data-sku": _vm.sku },
        on: {
          click: function($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.$emit("on-not-interested")
          }
        }
      },
      [_vm._v("\n    " + _vm._s(_vm.$t("I am not interested")) + "\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }