import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import config from 'config'
import isEmpty from 'lodash-es/isEmpty'
import {
  categoryFilters,
  empleadosFilters,
  facturacionFilters,
  decisionFilters,
  ciudadFilters
} from './filters'

export const filters_names = ['category', 'empleados', 'intervalo_facturacion', 'ciclo_decision', 'ciudad']

export function formatMoney (amount, decimalCount = 2, decimal = ',', thousands = '.', currencySymbol = '€') {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i: any = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
    let j = (i.length > 3) ? i.length % 3 : 0

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '') + currencySymbol
  } catch (e) {
    console.log(e)
  }
}

export function unformatMoney (value, decimal) {
  // Fails silently (need decent errors):
  value = value || 0

  // Return the value as-is if it's already a number:
  if (typeof value === 'number') return value

  // Default decimal point comes from settings, but could be set to eg. "," in opts:
  decimal = decimal || '.'

  // Build regex to strip out everything except digits, decimal point and minus sign:
  let regex = new RegExp('[^0-9-' + decimal + ']', 'g')
  let unformatted = parseFloat(('' + value)
    .replace(/\((?=\d+)(.*)\)/, '-$1') // replace bracketed values with negatives
    .replace(regex, '') // strip out any cruft
    .replace(decimal, '.') // make sure decimal point is standard
  )

  // This will fail silently which may cause trouble, let's wait and see:
  return !isNaN(unformatted) ? unformatted : 0
}

export function stringToId (text: string) {
  text = text.replace(/[^a-zA-Z0-9]/g, '')
  return parseInt(text.substr(0, text.length / 2), 36) + parseInt(text.substr(text.length / 2, text.length / 2), 36)
}

export function strNumToSKU (strNumber: string) {
  const sku = 'RP # ' + strNumber.padStart(8, '0')
  console.log(`String number ${strNumber} converted to SKU ${sku}`)
  return sku
}

export function getProductsFilters (products: any) {
  let filters = {}
  filters['category'] = [...categoryFilters]
  filters['empleados'] = [...empleadosFilters]
  filters['intervalo_facturacion'] = [...facturacionFilters]
  filters['ciclo_decision'] = [...decisionFilters]
  filters['ciudad'] = [...ciudadFilters]

  // for (let filter_name of filters_names) {
  //   if (filter_name !== 'ciudad') continue
  //   let filterId = 0
  //   let filterVariations = []

  //   for (let product of products) {
  //     if (product[filter_name] !== null) {
  //       let filter: any
  //       if (filter_name === 'category') {
  //         for (let category of product.category) {
  //           filter = {
  //             id: category.category_id,
  //             label: category.name,
  //             type: filter_name
  //           }
  //           let existingVariation = filterVariations.filter(variation => variation.label === filter.label)
  //           if (existingVariation.length === 0) {
  //             filterVariations.push(filter)
  //           }
  //         }
  //       } else {
  //         let label = product[filter_name]
  //         let _id = filterId
  //         if (filter_name === 'aic') { // || filter_name === 'facturacion') {
  //           _id = label
  //           label = formatMoney(label)
  //         } else if (filter_name === 'empleados') {
  //           _id = parseInt(label)
  //         } else {
  //           _id = stringToId(label)
  //         }
  //         filter = {
  //           id: _id,
  //           label: label,
  //           type: filter_name
  //         }
  //         let existingVariation = filterVariations.filter(variation => variation.label === filter.label)
  //         if (existingVariation.length === 0) {
  //           filterVariations.push(filter)
  //           filterId++
  //         }
  //       }
  //     }
  //   }

  //   if (filterVariations.length > 0) {
  //     filters[filter_name] = filterVariations
  //   }
  // }
  return filters
}

export function orderFilters (filters) {
  let orderedFilters = {}
  for (let [key, value] of Object.entries(filters)) {
    if (Array.isArray(value)) {
      orderedFilters[key] = [...value]
      orderedFilters[key].sort((a, b) => a.label.localeCompare(b.label))
    }
  }
  return orderedFilters
}

export function prepareNewQuery () {
  let query = new SearchQuery()
  query = query
    .applyFilter({key: 'visibility', value: {'in': [2, 3, 4]}})
    .applyFilter({key: 'status', value: {'in': [1]}})

  if (config.products.listOutOfStockProducts === false) {
    query = query.applyFilter({key: 'stock.is_in_stock', value: {'eq': true}})
  }

  return query
}

export function applyNumFiltersToQuery (query: any, filters: any) {
  if (!filters || isEmpty(filters)) return query

  let queryFilters = {
    aic: [],
    // facturacion: [],
    empleados: []
  }
  for (const [filterKey, filterVariations] of Object.entries(filters)) {
    if (filterKey === 'aic') { // || filterKey === 'facturacion') {
      for (const variation of filterVariations as any[]) {
        queryFilters[filterKey].push(unformatMoney(variation.label, ','))
      }
    } else if (filterKey === 'empleados') {
      for (const variation of filterVariations as any[]) {
        queryFilters[filterKey].push(parseInt(variation.label))
      }
    }
  }

  for (const [filterKey, filterValues] of Object.entries(queryFilters)) {
    if (filterValues.length > 0) {
      let filterObj = {key: filterKey, value: {'in': filterValues}}
      query = query.applyFilter(filterObj)
    }
  }

  return query
}

export function applyCategoriesToQuery (query: any, filters: any) {
  if (!filters || isEmpty(filters)) return query
  if (!filters.category) return query

  let catIds = []
  for (const [filterKey, filterVariations] of Object.entries(filters)) {
    if (filterKey === 'category') {
      for (const variation of filterVariations as any[]) {
        catIds.push(variation.id)
      }
    }
  }
  query = query.applyFilter({ key: 'category_ids', value: { 'in': catIds } })

  return query
}

export function getTextFilterTypes (filters: any) {
  let filterTypes = []
  if (filters.ciudad) filterTypes.push('ciudad')
  if (filters.intervalo_facturacion) filterTypes.push('intervalo_facturacion')
  if (filters.ciclo_decision) filterTypes.push('ciclo_decision')
  return filterTypes
}

export function applySearchText (products: any, searchText: string, searchInSku: boolean = false) {
  if (!searchText || searchText === '') return products
  if (!products || isEmpty(products)) return products

  // searchText = searchText.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2').normalize()
  let searchWords = searchText.split(' ').filter(word => word.length > 1)
  let filteredProducts = products.filter(product => {
    let match = false
    if (searchInSku) {
      match = product.sku.indexOf(searchText) > -1
    }
    if (!match) {
      match = searchWords.every(word => product.name.toLowerCase().indexOf(word.toLowerCase()) > -1)
    }
    return match
  })

  return filteredProducts
}

export function applyTextFiltersToProducts (products: any, filters: any) {
  if (!filters || isEmpty(filters)) return products
  if (!products || isEmpty(products)) return products
  console.log(`applyTextFiltersToProducts`)

  let filterTypes = getTextFilterTypes(filters)
  if (filterTypes.length === 0) return products

  let filteredProducts = []
  for (const product of products) {
    console.log(`applyTextFiltersToProducts. Product.name = ${product.name}`)
    let matches = []
    for (const type of filterTypes) {
      let match = [type, false]
      matches.push(match)
    }
    for (const [filterKey, filterVariations] of Object.entries(filters)) {
      console.log(`applyTextFiltersToProducts. filterKey = ${filterKey}`)
      if (filterTypes.includes(filterKey)) {
        for (const variation of filterVariations as any[]) {
          console.log('applyTextFiltersToProducts. product[filterKey] = ' + product[filterKey])
          console.log('applyTextFiltersToProducts. variation.label = ' + variation.label)
          if (product[filterKey] === variation.label) {
            for (let i = 0; i < matches.length; i++) {
              if (matches[i][0] === filterKey) matches[i][1] = true
            }
            console.log('applyTextFiltersToProducts. TEXT FILTER APPLIED')
            break
          }
        }
      }
    }
    let allMatchesAreTrue = true
    for (const match of matches) {
      if (!match[1]) {
        allMatchesAreTrue = false
        break
      }
    }
    if (allMatchesAreTrue) {
      filteredProducts.push(product)
    }
  }
  console.log(`applyTextFiltersToProducts. filteredProducts = ${JSON.stringify(filteredProducts)}`)

  return filteredProducts
}

export function getRecommendedProducts (products: any, userSalesforceId: string) {
  if (!userSalesforceId || userSalesforceId === '') return products

  let recommendedProducts = []
  for (let product of products) {
    if (product.recomendado && product.recomendado.indexOf(userSalesforceId) > -1) {
      recommendedProducts.push(product)
    }
  }

  return recommendedProducts
}

export function applyNotInterested (products: any, notInterested: string) {
  if (!notInterested || notInterested === '') return products

  let filteredProducts = []
  for (let product of products) {
    if (notInterested.indexOf(product.sku) === -1) {
      filteredProducts.push(product)
    }
  }

  return filteredProducts
}

export function applyShowbaasOutofstock (products: any) {
  let filteredProducts = []
  for (let product of products) {
    if (product.stock && product.stock.is_in_stock) {
      filteredProducts.push(product)
    } else if (product.showbaas_outofstock === 1) {
      filteredProducts.push(product)
    }
  }

  return filteredProducts
}

export function removeBoughtProducts (products: any, ordersHistory: any) {
  if (!products || products.length < 1) return products
  if (!ordersHistory || ordersHistory.length < 1) return products

  return products.filter((product, productIndex, productsArray) => {
    return !ordersHistory.some((order, orderIndex, ordersArray) => {
      let isBought = order.items[0].sku === product.sku
      if (isBought) console.log(`Product with SKU ${product.sku} was hidden because is already bought`)
      return isBought
    })
  })
}

export function moveUnavailableProducts (products: any) {
  if (!products || products.length < 1) return products
  let availableProducts = products.filter(product => product.stock && product.stock.is_in_stock)
  let unavailableProducts = products.filter(product => !(product.stock && product.stock.is_in_stock))
  return availableProducts.concat(unavailableProducts)
}
