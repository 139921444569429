<template>
  <div :data-sku="sku">
    <span
      @click.stop.prevent="$emit('on-not-interested')"
      class="corporate pointer uppercase"
      :data-sku="sku"
    >
      {{ $t('I am not interested') }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'NotInterested',
  props: {
    sku: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
