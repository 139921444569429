var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: {
        id: "product",
        itemscope: "",
        itemtype: "http://schema.org/Product"
      }
    },
    [
      _c(
        "section",
        { staticClass: "bg-cl-secondary px20 product-top-section" },
        [
          _c("div", { staticClass: "container" }, [
            _c("section", { staticClass: "row m0 between-xs" }, [
              _c(
                "div",
                { staticClass: "col-xs-12 col-md-6 center-xs middle-xs image" },
                [
                  _c(
                    "amp-carousel",
                    {
                      staticClass: "product-gallery",
                      attrs: {
                        width: "450",
                        height: "500",
                        layout: "responsive",
                        type: "slides"
                      }
                    },
                    _vm._l(_vm.gallery, function(image) {
                      return _c("amp-img", {
                        key: image.src,
                        staticClass: "product-image",
                        attrs: {
                          layout: "responsive",
                          src: image.src,
                          width: 450,
                          height: 500
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-xs-12 col-md-5 data" },
                [
                  _c("breadcrumbs", { staticClass: "pt40 pb20 hidden-xs" }),
                  _vm._v(" "),
                  _c(
                    "h1",
                    {
                      staticClass: "mb20 mt0 cl-mine-shaft product-name",
                      attrs: { "data-testid": "productName", itemprop: "name" }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm._f("htmlDecode")(_vm.product.name)) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb20 uppercase cl-secondary" }, [
                    _vm._v(
                      "\n            sku: " +
                        _vm._s(_vm.product.sku) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      attrs: {
                        itemprop: "offers",
                        itemscope: "",
                        itemtype: "http://schema.org/Offer"
                      }
                    },
                    [
                      _c("meta", {
                        attrs: {
                          itemprop: "priceCurrency",
                          content: _vm.priceCurrency
                        }
                      }),
                      _vm._v(" "),
                      _c("meta", {
                        attrs: {
                          itemprop: "price",
                          content: parseFloat(
                            _vm.product.price_incl_tax
                          ).toFixed(2)
                        }
                      }),
                      _vm._v(" "),
                      _vm.product.type_id !== "grouped"
                        ? _c("div", { staticClass: "mb40 price serif" }, [
                            _vm.product.special_price &&
                            _vm.product.price_incl_tax &&
                            _vm.product.original_price_incl_tax
                              ? _c("div", { staticClass: "h3 cl-secondary" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "h2 cl-mine-shaft weight-700"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("price")(
                                              _vm.product.price_incl_tax *
                                                _vm.product.qty,
                                              _vm.storeView
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" \n                "),
                                  _c(
                                    "span",
                                    { staticClass: "price-original h3" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("price")(
                                              _vm.product
                                                .original_price_incl_tax *
                                                _vm.product.qty,
                                              _vm.storeView
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.product.special_price &&
                            _vm.product.price_incl_tax
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "h2 cl-mine-shaft weight-700"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm._f("price")(
                                            _vm.product.price_incl_tax *
                                              _vm.product.qty,
                                            _vm.storeView
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.product.type_id == "configurable" && !_vm.loading
                        ? _c("div", { staticClass: "cl-primary variants" }, [
                            _vm.product.errors &&
                            Object.keys(_vm.product.errors).length > 0
                              ? _c("div", { staticClass: "error" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("formatProductMessages")(
                                          _vm.product.errors
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.product.type_id == "grouped" && !_vm.loading
                    ? _c("product-links", {
                        attrs: { products: _vm.product.product_links }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.product.bundle_options &&
                  _vm.product.bundle_options.length > 0 &&
                  !_vm.loading
                    ? _c("product-bundle-options", {
                        attrs: { product: _vm.product }
                      })
                    : _vm.product.custom_options &&
                      _vm.product.custom_options.length > 0 &&
                      !_vm.loading
                    ? _c("product-custom-options", {
                        attrs: { product: _vm.product }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row m0" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "button-full text-center block w-100 px10 py20 bg-cl-mine-shaft :bg-cl-th-secondary ripple weight-400 h4 cl-white sans-serif fs-medium col-xs-12 col-sm-4 col-md-6",
                          attrs: {
                            to: _vm.localizedRoute({
                              name: _vm.product.type_id + "-product",
                              params: {
                                parentSku: _vm.product.parentSku
                                  ? _vm.product.parentSku
                                  : _vm.product.sku,
                                slug: _vm.product.slug,
                                childSku: _vm.product.sku
                              }
                            })
                          }
                        },
                        [
                          _vm._v(
                            "\n              Check available sizes\n            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "container px15 pt50 pb35 cl-accent details" },
        [
          _c("h2", { staticClass: "h3 m0 mb10 serif lh20 details-title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("Product details")) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "h4 details-wrapper",
              class: { "details-wrapper--open": _vm.detailsOpen }
            },
            [
              _c("div", { staticClass: "row between-md m0" }, [
                _c("div", { staticClass: "col-xs-12 col-sm-6" }, [
                  _c("div", {
                    staticClass: "lh30 h5",
                    attrs: { itemprop: "description" },
                    domProps: { innerHTML: _vm._s(_vm.product.description) }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-xs-12 col-sm-5" }, [
                  _c(
                    "ul",
                    { staticClass: "attributes p0 pt5 m0" },
                    _vm._l(_vm.customAttributes, function(attr) {
                      return _c("product-attribute", {
                        key: attr.attribute_code,
                        attrs: {
                          product: _vm.product,
                          attribute: attr,
                          "empty-placeholder": "N/A"
                        }
                      })
                    }),
                    1
                  )
                ])
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }